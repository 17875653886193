// ==================================================
//  モバイル判定イベント
// ==================================================
function is_mobile() {
  const intFrameWidth = window.innerWidth;
  return intFrameWidth <= 767 ? true : false;
}

// ==================================================
//  ヘッダーメニューボタン
// ==================================================
const menu = document.querySelector(".header_menu-js");
const menuActiveClass = "header_menu-js-active";
const menuButton = document.querySelector(".header_menuButton-js");
const menuButtonActiveClass = "header_menuButton-js-active";
function headerMenuToggle() {
  menu.classList.toggle(menuActiveClass);
  menuButton.classList.toggle(menuButtonActiveClass);
}
menuButton.addEventListener("click", (event) => {
  headerMenuToggle();
});

// ==================================================
//  アンカーリンクのスムーススクロール
// ==================================================
const anchors = document.querySelectorAll('a[href^="#"]');
let headerHeight = document.querySelector("header").offsetHeight;
headerHeight = 0;
const urlHash = location.hash;

for (let i = 0; i < anchors.length; i++) {
  anchors[i].addEventListener("click", (e) => {
    e.preventDefault();

    /* ==================================================
     * ヘッダーメニューが開いていたら閉じる
     */
    // if (menu.classList.contains(menuActiveClass)) {
    //   headerMenuToggle();
    // }
    /* ================================================== */

    const href = anchors[i].getAttribute("href");
    if (href !== "#top" && href !== "#") {
      const target = document.getElementById(href.replace("#", ""));
      const position = window.pageYOffset + target.getBoundingClientRect().top - headerHeight;
      window.scroll({
        top: position,
        behavior: "smooth",
      });
    } else {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    }
  });
}

// ==================================================
//  アンカーリンクのページ遷移位置調整
// ==================================================
// window.addEventListener("load", (event) => {
//   const url = location.href;
//   const headerHeight = document.querySelector("header").offsetHeight;

//   if (url.indexOf("#") != -1) {
//     const anchor = url.split("#");
//     const target = $("#" + anchor[anchor.length - 1]);
//     const position = Math.floor(target.offset().top) - headerHeight;
//     window.scroll({
//       top: position,
//       behavior: "auto",
//     });
//   }
// });

// ==================================================
//  スクロールと連動したアニメーション
// ==================================================
// const callback = (entries) => {
//   entries.forEach((entry) => {
//     if (entry.isIntersecting) {
//       entry.target.classList.add("scrollAnimation-js-active");
//     }
//   });
// };
// const options = {
//   root: null,
//   rootMargin: "60% 0px -40% 0px",
//   threshold: 0,
// };
// let observer = new IntersectionObserver(callback, options);
// const animationItems = document.querySelectorAll(".scrollAnimation-js");
// animationItems.forEach((item) => {
//   observer.observe(item);
// });

// ==================================================
//  追従要素の横スクロール連動
// ==================================================
const fixedScroll = document.querySelector(".fixedScroll-js");
window.addEventListener("scroll", () => {
  fixedScroll.style.left = 20 - window.scrollX + "px";
});

// ==================================================
//  Splide Cococheck
// ==================================================
document.addEventListener("DOMContentLoaded", function () {
  if (document.getElementById("cococheck") != null) {
    const cococheckSplide = new Splide("#cococheck", {
      type: "loop",
      perPage: 4,
      perMove: 1,
      pagination: false,
      gap: "15px",
      breakpoints: {
        // max767
        767: {
          perPage: 1,
          gap: "0px",
        },
      },
    });
    cococheckSplide.mount();
  }

  if (document.getElementById("worksSlider") != null) {
    const worksSliderSplide = new Splide("#worksSlider", {
      type: "loop",
      perPage: 3,
      perMove: 1,
      arrows: false,
      pagination: false,
      padding: "20.9rem",
      gap: "29px",
      autoplay: true,
      interval: 5000,
      breakpoints: {
        // max767
        767: {
          perPage: 1,
          padding: "8rem",
          gap: "15px",
        },
      },
    });
    worksSliderSplide.mount();
  }
});
